import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import ChainInfo from "../../views/chain-info";
import { Seo } from "../../components/seo";
import { getBanner } from "../../components/utils";
import Code from "../../components/Code";

export const Head = ({ location, data: { chainsJson: chain, allFile: files } }) => (
  <Seo
    title={`${chain.name} halt restoration`}
    description={chain.about}
    pathname={location.pathname}
    image={getBanner(chain.key, files.edges)}
  />
);

const Chain = props => {
  const { chainsJson, allFile } = props.data;
  return (
    <Layout isMainnet>
      <ChainInfo chain={chainsJson} data={allFile.edges} />
      <h2>Restoring the chain post Dragonberry</h2>
      <p>First off, if your node is running, stop the node. It should be stopped already.</p>
      <h3>Install dependencies (if you need them)</h3>
      <Code language="bash">
        {`sudo apt update
sudo apt install -y curl git jq lz4 build-essential
sudo rm -rf /usr/local/go
sudo curl -Ls https://go.dev/dl/go1.19.3.linux-amd64.tar.gz | sudo tar -C /usr/local -xz
tee -a $HOME/.profile > /dev/null << EOF
export PATH=$PATH:/usr/local/go/bin
EOF
source $HOME/.profile

# Change this if your Dig home folder is elsewhere!
DIG_HOME=$HOME/.dig`}
      </Code>
      <h3>Compile Dig v3.2.2</h3>
      <Code language="bash">
        {`cd $HOME
git clone https://github.com/notional-labs/dig.git
cd dig
git checkout tags/v3.2.2
make build`}
      </Code>
      <h3>Copy the binary to wherever you need it to be</h3>
      <p>This example can be used if you are using Cosmovisor</p>
      <Code language="bash">
        {`cp $HOME/go/bin/digd $DIG_HOME/cosmovisor/upgrades/v2/bin/`}
      </Code>
      <h3>Updated the data and config</h3>
      <Code language="bash">
        {`# Download snapshot
cp $DIG_HOME/data/priv_validator_state.json $DIG_HOME/priv_validator_state.json.backup
rm -rf $DIG_HOME/data
curl -L https://quicksync.ccvalidators.com/SNAPSHOTS/dig-1_20221029_default.tar.lz4 | lz4 -dc - | tar -xf - -C $DIG_HOME
mv $DIG_HOME/priv_validator_state.json.backup $DIG_HOME/data/priv_validator_state.json

# Update config
sed -i.bak -e "s|^minimum-gas-prices *=.*|minimum-gas-prices = \\"0.0025udig\\"|" $DIG_HOME/config/app.toml
sed -i.bak -e "s|^timeout_propose_delta *=.*|timeout_propose_delta = \\"5ms\\"|" $DIG_HOME/config/config.toml
sed -i.bak -e "s|^timeout_prevote_delta *=.*|timeout_prevote_delta = \\"5ms\\"|" $DIG_HOME/config/config.toml
sed -i.bak -e "s|^timeout_precommit_delta *=.*|timeout_precommit_delta = \\"5ms\\"|" $DIG_HOME/config/config.toml`}
      </Code>
      <h3>Now we wait...</h3>
      <p>Wait until the coordinated time: <strong>Sunday November 13th 12:00 CET, 11:00 UTC</strong>. At that time, start the node.</p>
      <h3>Run this AFTER the chain resumes</h3>
      <Code language="bash">
        {`# Change this if your Dig home folder is elsewhere!
DIG_HOME=$HOME/.dig

# Reset config
sed -i.bak -e "s|^timeout_propose_delta *=.*|timeout_propose_delta = \\"500ms\\"|" $DIG_HOME/config/config.toml
sed -i.bak -e "s|^timeout_prevote_delta *=.*|timeout_prevote_delta = \\"500ms\\"|" $DIG_HOME/config/config.toml
sed -i.bak -e "s|^timeout_precommit_delta *=.*|timeout_precommit_delta = \\"500ms\\"|" $DIG_HOME/config/config.toml`}
      </Code>
      <p>Now restart the node so the new configuration can take effect.</p>
    </Layout>
  );
};

export default Chain;

export const query = graphql`
  query {
    chainsJson(key: { eq: "dig" }) {
      id
      about
      stakeUrl
      explorerUrl
      hidden
      key
      logo
      name
      site
      services {
        tmVersion
        gitRepo
        binary
        root
        publicRpc
        publicGrpc
        publicRest
        seedNode
        chainId
        denom
        snapshot
        installation {
          genesisUrl
          addrbookUrl
          seeds
          installScript
          versions {
            binary
            gitTag
            name
          }
        }
        stateSync {
          rpc
          peer
        }
      }
    }
    allFile {
      edges {
        node {
          id
          relativePath
          relativeDirectory
          publicURL
        }
      }
    }
  }
`;
